import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { UserPermissions, UserWithPermissions } from '../../types';
import { userDataSelectors } from '../../redux/userData';
import { ServerStatusCode } from '../../constants';
import { PageWrapper } from '../pageWrapper/PageWrapper';

interface Props {
  permissions: Array<keyof UserPermissions>;
  noPage?: boolean;
  requireAllPermissions?: boolean;
}

export function Guard({
  permissions,
  children,
  requireAllPermissions = true,
}: PropsWithChildren<Props>): JSX.Element | null {
  const user: UserWithPermissions | null = useSelector(
    userDataSelectors.getUserData,
  );

  const isPermissionsLoaded =
    user?.permissions &&
    permissions.every((permission) =>
      Object.keys(user.permissions).includes(
        permission as keyof UserPermissions,
      ),
    );
  const hasPermissions = requireAllPermissions
    ? permissions.every((permission) => user && user.permissions[permission])
    : permissions.some((permission) => user && user.permissions[permission]);

  if (!hasPermissions && isPermissionsLoaded) {
    return <PageWrapper statusCode={ServerStatusCode.FORBIDDEN} />;
  }

  return children as JSX.Element;
}

import React, { useEffect, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { API_STATUS } from '../../constants';
import { periodsSelectors } from '../../redux/periods';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { fetchLocations, locationsSelectors } from '../../redux/locations';
import { DepartmentBaseType, PeriodType } from '../../types';
import { formatYYYYMMDateToMMMMYString } from '../../utils';
import { Chip } from '@mui/material';
import {
  fetchRootDepartments,
  rootDepartmentsSelectors,
} from '../../redux/rootDepartments';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { TypeOfReport } from '../../types/reports';
import { userDataSelectors } from '../../redux/userData';
import { reportsSelectors } from '../../redux/reports';
import {
  clearContractors,
  contractorsSelectors,
  fetchDepartments,
} from '../../redux/contractors';
import { DepartmentView } from '../../types/contractor';
import { generateDepartmentList } from '../../utils/departments';

const ALL_LOCATIONS = 'All locations';
const ALL_DEPARTMENTS_ITEM: DepartmentBaseType = {
  id: '',
  name: 'All departments',
  zohoId: '',
  parentId: '',
  createdAt: '',
  updatedAt: '',
  deletedAt: null,
};

interface Props {
  period: PeriodType | null;
  locations: string[];
  rootDepartments: DepartmentBaseType[];
  typeOfReport: TypeOfReport;
  setPeriod: (period: PeriodType | null) => void;
  setLocations: (locations: string[]) => void;
  setRootDepartments: (departments: DepartmentBaseType[]) => void;
  setTypeOfReport: (type: TypeOfReport) => void;
}

const Filters: React.FC<Props> = ({
  period,
  locations,
  rootDepartments,
  typeOfReport,
  setPeriod,
  setLocations,
  setRootDepartments,
  setTypeOfReport,
}) => {
  const dispatch = useAppDispatch();
  const locationsList = useAppSelector(locationsSelectors.getLocationsList);
  const locationsApiStatus = useAppSelector(
    locationsSelectors.getLocationsApiStatus,
  );
  const departmentTree = useAppSelector(contractorsSelectors.getDepartmentTree);
  const reportsApiStatus = useAppSelector(reportsSelectors.getReportsApiStatus);
  const isLoading = reportsApiStatus === API_STATUS.LOADING;
  const periodsList = useAppSelector(periodsSelectors.getPeriodsList);
  const periodsApiStatus = useAppSelector(periodsSelectors.getPeriodsApiStatus);
  const reportTypeOptions = useMemo(
    () => Object.entries(TypeOfReport).map(([, value]) => value),
    [],
  );

  const rootDepartmentsList = useAppSelector(
    rootDepartmentsSelectors.getRootDepartmentsList,
  );
  const rootDepartmentsApiStatus = useAppSelector(
    rootDepartmentsSelectors.getRootDepartmentsApiStatus,
  );
  const user = useAppSelector(userDataSelectors.getUserData);

  const isPeriodsLoading = periodsApiStatus === API_STATUS.LOADING;
  const isLocationsLoading = locationsApiStatus === API_STATUS.LOADING;
  const isRootDepartmentsLoading =
    rootDepartmentsApiStatus === API_STATUS.LOADING;

  const locationFilters: string[] = useMemo(
    () => [ALL_LOCATIONS, ...locationsList],
    [locationsList],
  );

  const departmentFilters: DepartmentBaseType[] = useMemo(() => {
    if (typeOfReport === TypeOfReport.TEAM_COMPENSATION_PLAN) {
      if (departmentTree.length === 0) {
        return [ALL_DEPARTMENTS_ITEM];
      }
      const departmentTreeList = generateDepartmentList(departmentTree);
      const filteredRootDepartments = rootDepartmentsList.filter((department) =>
        departmentTreeList.includes(department.id),
      );
      return [ALL_DEPARTMENTS_ITEM, ...filteredRootDepartments];
    }
    return [ALL_DEPARTMENTS_ITEM, ...rootDepartmentsList];
  }, [rootDepartmentsList, departmentTree, typeOfReport]);

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchRootDepartments());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDepartments(DepartmentView.TEAMS));
    return () => {
      dispatch(clearContractors());
    };
  }, [dispatch]);

  const getDefaultDepartmentOptionChipDeleteIcon = (
    option: DepartmentBaseType,
  ) => {
    return option.id ? <CancelRoundedIcon /> : <></>;
  };

  const getDefaultLocationOptionChipDeleteIcon = (option: string) => {
    return option !== ALL_LOCATIONS ? <CancelRoundedIcon /> : <></>;
  };

  const handleLocationChange = (
    _e: React.SyntheticEvent<Element, Event>,
    options: string[],
  ) => {
    const recentSelection = options.pop();
    setLocations(
      recentSelection === ALL_LOCATIONS
        ? []
        : [
            ...options.filter((option) => option !== ALL_LOCATIONS),
            recentSelection ?? '',
          ].filter((x) => x),
    );
  };

  const handleDepartmenChange = (
    _e: React.SyntheticEvent<Element, Event>,
    options: DepartmentBaseType[],
  ) => {
    const recentSelection = options.pop();
    setRootDepartments(
      recentSelection?.id
        ? [...options.filter((option) => option.id), recentSelection].filter(
            (x) => x,
          )
        : [],
    );
  };

  return (
    <>
      <Grid item md={12}>
        <Autocomplete
          id="scope"
          openOnFocus
          clearIcon={null}
          componentsProps={{ clearIndicator: { disabled: true } }}
          onChange={(_e, option) => option && setTypeOfReport(option)}
          options={reportTypeOptions}
          value={typeOfReport}
          loading={isPeriodsLoading}
          disabled={!user?.permissions.canGenerateReport || isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Scope"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isPeriodsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item md={12}>
        <Autocomplete
          id="currentPeriod"
          openOnFocus
          clearIcon={null}
          componentsProps={{ clearIndicator: { disabled: true } }}
          onChange={(_e, option) => setPeriod(option)}
          options={periodsList}
          getOptionLabel={(option) =>
            formatYYYYMMDateToMMMMYString(option.period)
          }
          disabled={isLoading}
          value={period}
          isOptionEqualToValue={(value, option) => option.id === value.id}
          loading={isPeriodsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Period"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isPeriodsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item md={12}>
        <Autocomplete
          id="location"
          disableClearable
          disableCloseOnSelect
          openOnFocus
          multiple
          onChange={handleLocationChange}
          options={locationFilters}
          value={locations.length ? locations : [ALL_LOCATIONS]}
          loading={isLocationsLoading}
          disabled={isLoading}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              // eslint-disable-next-line
              <Chip
                label={option}
                {...getTagProps({ index })}
                deleteIcon={getDefaultLocationOptionChipDeleteIcon(option)}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Location"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLocationsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item md={12}>
        <Autocomplete
          id="root-departments"
          disableClearable
          disableCloseOnSelect
          openOnFocus
          multiple
          onChange={handleDepartmenChange}
          options={departmentFilters}
          getOptionLabel={(option) => option.name}
          value={
            rootDepartments.length ? rootDepartments : [ALL_DEPARTMENTS_ITEM]
          }
          loading={isRootDepartmentsLoading}
          disabled={isLoading}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              // eslint-disable-next-line
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                deleteIcon={getDefaultDepartmentOptionChipDeleteIcon(option)}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Departments"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isRootDepartmentsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default Filters;

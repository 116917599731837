import { DepartmentType } from '../types';

export function generateDepartmentList(
  departments: DepartmentType[],
): string[] {
  const departmentList: string[] = [];
  departments.forEach((department) => {
    const { parentId, id, subDepartments } = department;

    const departmentSubList = generateDepartmentList(subDepartments || []);
    departmentList.push(parentId as string, id);
    departmentList.push(...departmentSubList);
  });

  return departmentList;
}

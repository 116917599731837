import React, { useEffect, useCallback, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { StyledTableRow } from './StyledTableRow';
import { MarginHighlightCell } from './components/MarginHighlightCell';
import { MemberInfoCell } from '../../components/tableCellRenders';
import { CustomTableCell } from '../../components/customTable';
import { CustomBudgetCell, CustomBudgetBox } from './components';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ResetIcon from '@mui/icons-material/Restore';
import {
  MemberRatesUpdate,
  MemberRatesUpdateForm,
  UpdateDummyMemberProps,
} from './types';
import { useFormik } from 'formik';
import { debounce } from '../../utils/debounce';
import { compensationMarginsValidationSchema } from './CompensationMargins.schema';
import { toggleResetAll } from '../../redux/account';
import { useAppDispatch } from '../../redux/hooks';
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded';
import VerticalAlignBottomRoundedIcon from '@mui/icons-material/VerticalAlignBottomRounded';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { NumericFormat } from 'react-number-format';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ReportIssueModal from '../reportIssueModal/ReportIssueModal';
import { ReportIssueVariant } from '../reportIssueModal/ReportIssueModal';
import { CompensationRange, MarginHighlight, Member } from '../../types';
import { RateField } from './components/RateField';
import { iconColor, payRateColor } from '../../utils/business';

export interface TeamMemberViewProps
  extends Omit<
    Member,
    | 'legalLocation'
    | 'email'
    | 'seniorityLevel'
    | 'expectedPayRate'
    | 'companyRange'
    | 'isManager'
    | 'netMargin'
  > {
  marginHighlights: MarginHighlight[];
  isLastRow: boolean;
  isRestricted?: boolean | false;
  isHidden?: boolean;
  resetAllMode: boolean;
  legalLocation?: string;
  city?: string;
  handleRatesChange: (values: MemberRatesUpdate) => void;
  handleUpdateMember: (values: UpdateDummyMemberProps) => void;
  accountName?: string;

  isEditableMode: boolean;
  projectId?: string;
}

export const TeamMemberView = ({
  id: memberId,
  fullName,
  seniority,
  stream,
  resource,
  compensationRate,
  companyRateCard,
  allocation,
  grossMargin,
  marginHighlights,
  isLastRow,
  isEditableMode,
  resetAllMode,
  isRestricted,
  isHidden,
  handleRatesChange,
  handleUpdateMember,
  compensationRange,
  avatarUrl,
  actualPayRate,
  compensationType,
  legalLocation,
  city,
  projectId,
  accountName,
}: TeamMemberViewProps) => {
  const dispatch = useAppDispatch();

  const [reportMenuVisible, setReportMenuVisible] =
    useState<null | HTMLElement>(null);
  const [reportIncorrectModalVisible, setReportIncorrectModalVisible] =
    useState(false);
  const open = Boolean(reportMenuVisible);

  const formik = useFormik<MemberRatesUpdateForm>({
    initialValues: {
      memberId,
      compensationRate: compensationRate || 0,
      billRate: resource?.billRate || 0,
      allocation: allocation ?? 100,
      isHidden: isHidden ?? false,
      payRange: [compensationRange?.min || 0, compensationRange?.max || 0],
    },
    validationSchema: compensationMarginsValidationSchema,
    onSubmit: (values) => {
      if (!projectId) return;

      handleRatesChange({
        ...values,
        projectId,
      });
    },
    enableReinitialize: true,
  });

  const debouncedSubmit = useCallback(
    () => debounce(formik.submitForm, 500),
    [formik],
  );
  const handleClose = useCallback(() => {
    setReportMenuVisible(null);
  }, [setReportMenuVisible]);

  const handleModalClose = useCallback(() => {
    setReportIncorrectModalVisible(false);
  }, [setReportIncorrectModalVisible]);

  const handleSelectMenu = useCallback(() => {
    setReportIncorrectModalVisible(true);
    setReportMenuVisible(null);
  }, [setReportIncorrectModalVisible]);

  const handleReportMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setReportMenuVisible(event.currentTarget);
    },
    [setReportMenuVisible],
  );

  const onRateReset = useCallback(
    (data: {
      compensationRate: number;
      billRate: number;
      allocation: number;
    }) => {
      if (!projectId) return;

      const { compensationRate, billRate, allocation } = data;
      const fieldsToUpdate = [
        { fieldName: 'compensationRate', value: compensationRate },
        { fieldName: 'billRate', value: billRate },
        { fieldName: 'allocation', value: allocation },
      ];

      fieldsToUpdate.forEach(({ fieldName, value }) => {
        handleUpdateMember({ memberId, fieldName, value, projectId });
        formik.setFieldValue(fieldName, value);
      });
      debouncedSubmit();
    },
    [formik, projectId, memberId, debouncedSubmit, handleUpdateMember],
  );

  const onRateChange = useCallback(
    (data: { fieldName: string; value?: number }) => {
      if (!projectId) return;

      formik.setFieldValue(data.fieldName, data.value);
      handleUpdateMember({
        memberId: memberId,
        fieldName: data.fieldName,
        value: data.value,
        projectId,
      });
      debouncedSubmit();
    },
    [formik, handleUpdateMember, memberId, debouncedSubmit, projectId],
  );

  const handleAllocationChange = useCallback(
    (value?: number | undefined) => {
      if (!projectId) return;

      formik.setFieldValue('allocation', value);
      handleUpdateMember({
        memberId: memberId,
        fieldName: 'allocation',
        value: value,
        projectId,
      });
      debouncedSubmit();
    },
    [formik, handleUpdateMember, memberId, debouncedSubmit, projectId],
  );

  const onAddRemoveIconClick = useCallback(() => {
    if (!projectId) return;

    handleRatesChange({
      memberId,
      compensationRate: formik.values.compensationRate,
      billRate: formik.values.billRate,
      allocation: formik.values.allocation,
      isHidden: !isHidden,
      payRange: formik.values.payRange,
      projectId,
    });
  }, [
    projectId,
    formik.values.allocation,
    formik.values.billRate,
    formik.values.compensationRate,
    formik.values.payRange,
    isHidden,
    memberId,
    handleRatesChange,
  ]);

  useEffect(() => {
    if (resetAllMode) {
      onRateReset({
        compensationRate: compensationRate ?? 0,
        billRate: resource?.billRate ?? 0,
        allocation: allocation ?? 100,
      });
      dispatch(toggleResetAll());
      debouncedSubmit();
    }
    // we are disabling it as we can't add onRateReset,dispatch and debouncedSubmit in dependency for now due to infinite update loop.
    // eslint-disable-next-line
  }, [resetAllMode, compensationRate, resource?.billRate, toggleResetAll]);

  const columnWidth = (theme: Theme) => theme.spacing(15);
  const nameColumnWidth = 370;
  const restrictedUserMessage =
    "You don't have permissions to view financial data for this user";

  return (
    <StyledTableRow isLastRow={isLastRow} isHidden={isHidden}>
      <CustomTableCell
        sx={{ borderBottom: '0', minWidth: nameColumnWidth }}
        pure
        nowrap
      >
        <MemberInfoCell
          userInfo={{
            fullName: fullName,
            stream: `${seniority} ${stream}`.trim(),
            avatarUrl: avatarUrl,
            country: legalLocation,
            city: city,
          }}
        />
      </CustomTableCell>
      {isRestricted ? (
        <>
          <CustomTableCell colSpan={6} pure>
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                pl: 4,
              }}
            >
              <LockOutlinedIcon color="disabled" />
              <Typography
                sx={{ alignSelf: 'center', px: 0.5 }}
                variant="body2"
                color="text.disabled"
              >
                {restrictedUserMessage}
              </Typography>
            </Box>
          </CustomTableCell>
        </>
      ) : (
        <>
          <MarginHighlightCell
            align="center"
            marginHighlights={marginHighlights || []}
            value={grossMargin}
            sx={{ borderBottom: '0', width: columnWidth }}
          >
            {grossMargin == null ? 'NA' : `${grossMargin}%`}
          </MarginHighlightCell>
          {isEditableMode ? (
            <CustomBudgetCell justifyContent="center">
              <RateField
                id={'compensationRate'}
                name={'compensationRate'}
                value={formik.values.compensationRate}
                handleChange={onRateChange}
                error={Boolean(formik.errors.compensationRate)}
                disabled={isHidden}
              />
            </CustomBudgetCell>
          ) : (
            <CustomBudgetCell
              icon={
                <PayRateIcon
                  compensationRange={compensationRange}
                  compensationRate={compensationRate}
                />
              }
              justifyContent="center"
              align="center"
            >
              {compensationRate == null ? (
                'NA'
              ) : (
                <>
                  {actualPayRate && compensationType ? (
                    <Tooltip
                      title={`$${actualPayRate} ${compensationType}`}
                      placement="top"
                    >
                      <Box>
                        <>
                          <CustomBudgetBox>$</CustomBudgetBox>
                          {'\u2009'}
                          {compensationRate}
                        </>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box>
                      <>
                        <CustomBudgetBox>$</CustomBudgetBox>
                        {'\u2009'}
                        {compensationRate}
                      </>
                    </Box>
                  )}
                </>
              )}
            </CustomBudgetCell>
          )}

          <CustomBudgetCell
            align="center"
            justifyContent="center"
            sx={{ width: columnWidth }}
          >
            {compensationRange?.min == null ? (
              'NA'
            ) : (
              <Tooltip
                title={
                  <>
                    Min: ${compensationRange?.min} | Max: $
                    {compensationRange?.max} | Approve with PDM: $
                    {compensationRange?.approvedMax}
                  </>
                }
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 'none',
                    },
                  },
                }}
              >
                <Box>
                  <CustomBudgetBox>$</CustomBudgetBox>
                  {'\u2009'}
                  {compensationRange?.min}
                  {'\u2009'}
                  <CustomBudgetBox>..</CustomBudgetBox>
                  {'\u2009'}
                  <CustomBudgetBox>$</CustomBudgetBox>
                  {'\u2009'}
                  {compensationRange?.approvedMax}
                </Box>
              </Tooltip>
            )}
          </CustomBudgetCell>

          {isEditableMode ? (
            <>
              <CustomBudgetCell justifyContent="center">
                <RateField
                  id={'billRate'}
                  name={'billRate'}
                  value={formik.values.billRate}
                  handleChange={onRateChange}
                  error={Boolean(formik.errors.billRate)}
                  disabled={isHidden}
                />
              </CustomBudgetCell>
            </>
          ) : (
            <>
              <CustomBudgetCell justifyContent="center">
                <CustomBudgetBox>$</CustomBudgetBox>
                {'\u2009'}
                {resource?.billRate}
              </CustomBudgetCell>
            </>
          )}

          <CustomBudgetCell
            align="center"
            sx={{ borderBottom: '0', width: columnWidth }}
            justifyContent="center"
          >
            {companyRateCard?.billRate == null ? (
              'NA'
            ) : (
              <>
                <CustomBudgetBox>$</CustomBudgetBox>
                {'\u2009'}
                {companyRateCard?.billRate}
              </>
            )}
          </CustomBudgetCell>

          {isEditableMode ? (
            <CustomBudgetCell justifyContent="center">
              <Tooltip
                open={Boolean(formik.errors.allocation)}
                title={formik.errors.allocation ?? ''}
              >
                <Box>
                  <NumericFormat
                    customInput={TextField}
                    autoComplete="off"
                    fullWidth
                    id="allocation"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      size: 'small',
                      sx: {
                        maxWidth: (theme: Theme) => theme.spacing(8.5),
                        minWidth: (theme: Theme) => theme.spacing(7.5),
                        maxHeight: (theme: Theme) => theme.spacing(4.5),
                      },
                    }}
                    inputProps={{ maxLength: 3 }}
                    name="allocation"
                    inputMode="decimal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(formik.errors.allocation)}
                    onValueChange={({ floatValue }) =>
                      handleAllocationChange(floatValue)
                    }
                    value={formik.values.allocation}
                    variant="filled"
                    hiddenLabel
                  />
                </Box>
              </Tooltip>
            </CustomBudgetCell>
          ) : (
            <TableCell
              color="text.disabled"
              align="center"
              sx={{ width: columnWidth }}
            >
              {allocation === undefined || allocation === null
                ? 'NA'
                : `${allocation}%`}
            </TableCell>
          )}
        </>
      )}

      {isEditableMode && (
        <TableCell
          align="right"
          sx={{
            borderBottom: '0',
            minWidth: (theme: Theme) => theme.spacing(15),
          }}
        >
          {!isRestricted && (
            <IconButton
              onClick={() => {
                onRateReset({
                  compensationRate: compensationRate ?? 0,
                  billRate: resource?.billRate ?? 0,
                  allocation: allocation ?? 100,
                });
              }}
              color={!isHidden ? 'default' : 'primary'}
            >
              {<ResetIcon />}
            </IconButton>
          )}
          <IconButton
            onClick={onAddRemoveIconClick}
            color={!isHidden ? 'default' : 'primary'}
          >
            {!isHidden ? <PersonOffOutlinedIcon /> : <PersonAddOutlinedIcon />}
          </IconButton>
        </TableCell>
      )}

      {!isEditableMode && (
        <TableCell
          align="right"
          sx={{
            borderBottom: '0',
            minWidth: (theme: Theme) => theme.spacing(15),
          }}
        >
          <IconButton onClick={handleReportMenu}>
            <MoreVertIcon />
          </IconButton>

          <Menu anchorEl={reportMenuVisible} open={open} onClose={handleClose}>
            <MenuItem onClick={handleSelectMenu}>
              Report incorrect project
            </MenuItem>
          </Menu>
        </TableCell>
      )}

      {reportIncorrectModalVisible ? (
        <>
          <ReportIssueModal
            isModalOpen={reportIncorrectModalVisible}
            handleCloseModal={handleModalClose}
            reportIssueVariant={ReportIssueVariant.ReportIssueIncorrectFields}
            currentUserDetails={{
              fullName,
              stream,
              seniority,
              avatarUrl,
              city,
              country: legalLocation,
              accountName: accountName,
            }}
          />
        </>
      ) : null}
    </StyledTableRow>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

const PayRateIcon = ({
  compensationRate,
  compensationRange,
}: {
  compensationRate?: number | null;
  compensationRange?: CompensationRange;
}) => {
  const payRateIconColor = payRateColor({
    compensationRange,
    compensationRate,
  });

  if (compensationRate) {
    if (payRateIconColor === iconColor.aboveRangeIconColor) {
      return (
        <CustomWidthTooltip title="Above pay range" placement="top">
          <VerticalAlignTopRoundedIcon
            sx={{ height: '20px', color: 'error.main' }}
          />
        </CustomWidthTooltip>
      );
    }
    if (payRateIconColor === iconColor.belowRangeIconColor) {
      return (
        <CustomWidthTooltip title="Below pay range" placement="top">
          <VerticalAlignBottomRoundedIcon
            sx={{ height: '20px', color: 'primary.main' }}
          />
        </CustomWidthTooltip>
      );
    }
  }

  return <></>;
};

import { KpiStatus } from '../constants';
import { User } from './user';

export interface ReportType {
  trueup?: number | null;
  payout?: number;
  bonusType: string;
  department: string;
  fullName: string;
  location: string;
  period: string;
  metricName: string;
  city: string;
  status: KpiStatus;
  actionTaker?: User;
  frequency: string;
  baseCompensation?: number;
  bestCase?: number | null;
  worstCase?: number | null;
  bonusShare?: number | null;
  seniority?: string;
  role?: string;
  reportingToUser?: string;
  kpiOwner?: string;
  compensationType?: CompensationType;
  averageMonthlyHours?: number;
  isGroupedDataHeading?: boolean;
  isGroupedDataDetail?: boolean;
}

export interface GroupedData {
  [key: string]: {
    summary: ReportType & {
      bonusShare: number;
    };
    details: ReportType[];
  };
}

export enum TypeOfReport {
  METRICS = 'Metrics',
  TEAM_COMPENSATION_PLAN = 'Team compensation plan',
}

export enum CompensationType {
  HOURLY = 'hourly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

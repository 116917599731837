import Contractors from '../features/contractors/Contractors';
import { UserPermissions } from '../types';
import Submissions from '../features/submissions/Submissions';
import { KpiDefinitions } from '../features/kpiDefinitions/kpiDefinitions';
import { Approvals } from '../features/approvals/Approvals';
import Reports from '../features/reports/Reports';
import Contractor from '../features/contractor/Contractor';
import { paths } from '../constants/paths';
import { TermsOfUsePage } from '../pages/termsOfUse';
import Admin from '../features/admin/Admin';
import { Budgets } from '../features/budgets/Budgets';
import ClientInvoice from '../features/clientInvoice/ClientInvoice';

interface RouteItemModel {
  path: string;
  permissions: Array<keyof UserPermissions>;
  element: () => JSX.Element;
  requireAllPermissions?: boolean;
}

const rootRoutes: RouteItemModel[] = [
  {
    path: `${paths.contractors}`,
    permissions: ['canViewListOfContractors'],
    element: Contractors,
  },
  {
    path: `${paths.contractors}/:departmentId`,
    permissions: ['canViewListOfContractors'],
    element: Contractors,
  },
  {
    path: `${paths.contractors}/:departmentId/:userId`,
    permissions: ['canViewListOfContractors'],
    element: Contractor,
  },
  {
    path: paths.submissions,
    permissions: ['canSubmitKpiValues'],
    element: Submissions,
  },
  {
    path: paths.kpiDefinitions,
    permissions: ['canManageKpiDefinitions'],
    element: KpiDefinitions,
  },
  {
    path: paths.approvals,
    permissions: ['canApproveKpiValues'],
    element: Approvals,
  },
  {
    path: paths.reports,
    permissions: ['canGenerateReport', 'isCompensationManager'],
    element: Reports,
    requireAllPermissions: false,
  },
  {
    path: paths.clientInvoice,
    permissions: ['isFinance'],
    element: ClientInvoice,
  },
  {
    path: paths.myMetrics,
    permissions: ['canViewMyMetrics'],
    element: Contractor,
  },
  {
    path: paths.portfolio,
    permissions: ['hasAccounts', 'isCompensationManager'],
    element: Budgets,
  },
  {
    path: paths.termsOfUse,
    permissions: ['canViewMyMetrics'],
    element: TermsOfUsePage,
  },
  {
    path: paths.admin,
    permissions: ['canClosePeriod'],
    element: Admin,
  },
];

export { rootRoutes };
